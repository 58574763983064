import { SegmentDownloadSubscriberType } from '@attentive/reporting-platform';

import { SegmentCountMetric, SubscriberChannelType } from '../../hooks';

type SubscriberChannelMapType = Record<
  SubscriberChannelType,
  { label: string; metric: SegmentCountMetric | undefined }
>;

export const SubscriberChannelDataMap: SubscriberChannelMapType = {
  ALL: { label: 'All subscribers', metric: undefined },
  TEXT: { label: 'SMS', metric: 'sms' },
  EMAIL: { label: 'Email', metric: 'email' },
  MULTICHANNEL: { label: 'Multi-channel', metric: 'smsAndEmail' },
};

export const QueueReportSubscriberChannelMap: Record<
  SubscriberChannelType,
  SegmentDownloadSubscriberType
> = {
  ALL: 'ALL',
  TEXT: 'SMS',
  EMAIL: 'EMAIL',
  MULTICHANNEL: 'MULTICHANNEL',
};
