import React, { FC, Suspense, useEffect, useState } from 'react';
import { useQueryLoader } from 'react-relay';

import { ToastType, useCurrentUser, useToast } from '@attentive/acore-utils';
import { Button, LoadingIndicator, StandardDialog } from '@attentive/picnic';
import { useQueueScheduledReport } from '@attentive/reporting-platform';

import { SubscriberChannel, useDefaultChannel } from '../../hooks';
import { convertInternalSegmentIdToNodeId } from '../../utils';

import { RecipientAccount } from './RecipientsMultiSelectAdapter';
import { SegmentExportDialogBody, SegmentExportDialogLoading } from './SegmentExportDialogBody';
import { QueueReportSubscriberChannelMap } from './constants';
import { getSegmentExportReportId } from './utils';

import Query, {
  SegmentExportDialogBody_TargetingCommon_Query as QueryType,
} from './__generated__/SegmentExportDialogBody_TargetingCommon_Query.graphql';

interface SegmentExportDialogProps {
  onOpenChange: (open: boolean) => void;
  segmentInternalId: number;
  isOpen: boolean;
}

export const SegmentExportDialog: FC<SegmentExportDialogProps> = ({
  isOpen,
  onOpenChange,
  segmentInternalId,
}) => {
  const [createToast] = useToast();
  const segmentId = convertInternalSegmentIdToNodeId(segmentInternalId);
  const { company } = useCurrentUser();
  const { internalId: companyInternalId, id: companyId } = company;

  const [queryRef, loadQuery] = useQueryLoader<QueryType>(Query);
  const { queueScheduledReport, isLoading } = useQueueScheduledReport(companyInternalId);

  const defaultChannel = useDefaultChannel();
  const isMultiChannelCompany = defaultChannel === 'ALL';

  const [subscriberType, setSubscriberType] = useState<SubscriberChannel>(defaultChannel);
  const [recipientAccounts, setRecipientAccounts] = useState<RecipientAccount[]>([]);

  useEffect(() => {
    if (isOpen) {
      loadQuery({ companyId, segmentId });
    }
  }, [isOpen, loadQuery, companyId, segmentId]);

  const handleOpenChange = (open: boolean) => {
    setRecipientAccounts([]);
    setSubscriberType(defaultChannel);
    onOpenChange(open);
  };

  const handleScheduleReport = async () => {
    if (!recipientAccounts.length) {
      return;
    }
    await queueScheduledReport({
      metadata: {
        recipients: recipientAccounts.map((account) => account.email),
        reportId: getSegmentExportReportId(),
        segmentId: segmentInternalId,
        subscriberType: QueueReportSubscriberChannelMap[subscriberType],
      },
      onComplete: ({ scheduledRecipients, failedRecipients }) => {
        handleOpenChange(false);
        if (!scheduledRecipients.length) {
          createToast({
            type: ToastType.Error,
            text: 'Segment download not sent',
            title: 'Error',
          });
        }
        if (failedRecipients.length && scheduledRecipients.length) {
          createToast({
            type: ToastType.Error,
            text: `Segment download failed to send to the following recipient(s): ${failedRecipients.join(
              ', '
            )}`,
            title: 'Error',
          });
        } else {
          createToast({
            type: ToastType.Success,
            text: 'Segment download is being sent to the recipient(s).',
            title: 'Success',
          });
        }
      },
    });
  };

  return (
    <StandardDialog open={isOpen} onOpenChange={handleOpenChange}>
      {isOpen && (
        <StandardDialog.Content css={{ width: 612 }}>
          <StandardDialog.Header>
            <StandardDialog.Heading>Generate segment .CSV download</StandardDialog.Heading>
          </StandardDialog.Header>
          <StandardDialog.Body css={{ width: '100%' }}>
            {queryRef ? (
              <Suspense fallback={<SegmentExportDialogLoading />}>
                <SegmentExportDialogBody
                  queryRef={queryRef}
                  subscriberType={subscriberType}
                  setSubscriberType={setSubscriberType}
                  recipientAccounts={recipientAccounts}
                  setRecipientAccounts={setRecipientAccounts}
                  isMultiChannelCompany={isMultiChannelCompany}
                />
              </Suspense>
            ) : (
              <SegmentExportDialogLoading />
            )}
          </StandardDialog.Body>
          <StandardDialog.Footer>
            <StandardDialog.Close variant="secondary">Cancel</StandardDialog.Close>
            <Button onClick={handleScheduleReport} disabled={!recipientAccounts.length}>
              {isLoading ? <LoadingIndicator /> : 'Request download link'}
            </Button>
          </StandardDialog.Footer>
        </StandardDialog.Content>
      )}
    </StandardDialog>
  );
};
